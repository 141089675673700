import { AutobindToThis } from "./Decorators";
import { Validatable, validate } from "./Validation";

export class ProjectInput {

	private templateElement : HTMLTemplateElement;
	private hostElement : HTMLDivElement;
	private formElement : HTMLFormElement;
	private titleInputElement : HTMLInputElement;
	private descriptionInputElement : HTMLInputElement;
	private peopleInputElement : HTMLInputElement;

	constructor() {
		this.templateElement = document.getElementById("project-input") as HTMLTemplateElement;
		this.hostElement = document.getElementById("app") as HTMLDivElement;

		//.content gives a refference to the content of the template.
		const importedNode = document.importNode(this.templateElement.content, true /* Deep clone */);
		this.formElement = importedNode.firstElementChild as HTMLFormElement;
		this.formElement.id = "user-input"; //for styling

		// Populate input elements in the form
		this.titleInputElement = 		this.formElement.querySelector("#title") as HTMLInputElement;
		this.descriptionInputElement = 	this.formElement.querySelector("#description") as HTMLInputElement;
		this.peopleInputElement = 		this.formElement.querySelector("#people") as HTMLInputElement;

		this.configure();
		this.attach();
	}

	@AutobindToThis
	private gatherUserInput() : [string, string, number] | void {
		const enteredTitle = this.titleInputElement.value;
		const enteredDescription = this.descriptionInputElement.value;
		const enteredPeople = this.peopleInputElement.value;

		const validatableTitle : Validatable = {
			value : enteredTitle,
			required : true,
			stringLengthMin : 2,
			stringLengthMax : 20,
		};
		const validatableDescription : Validatable = {
			value : enteredDescription,
			required : true,
			stringLengthMin : 0,
			stringLengthMax : 80,
		};
		const validatablePeople : Validatable = {
			value : +enteredPeople,
			required : true,
			numberValueMin : 1,
			numberValueMax : 99,
		};

		if (
			!validate(validatableTitle) ||
			!validate(validatableDescription) ||
			!validate(validatablePeople)
		) {
			// At least 1 input is invalid.
			alert("Invalid input!");
			return; // void
		}
		return [
			enteredTitle,
			enteredDescription,
			+enteredPeople, // '+' casts string to number
		];
	}

	@AutobindToThis
	private submitHandler(event : Event) {
		event.preventDefault(); // Otherwise, a HTTP-request will be sent upon 'submitting' the form.

		const userInput = this.gatherUserInput();

		if (Array.isArray(userInput)) {
			const [title, description, people] = userInput;

			console.log(title);
			console.log(description);
			console.log(people);

			this.clearInputs();
		}

	}

	private clearInputs() {
		this.titleInputElement.value = "";
		this.descriptionInputElement.value = "";
		this.peopleInputElement.value = "";
	}

	private configure() : void {
		this.formElement.addEventListener("submit", this.submitHandler);
	}

	// Put element in the DOM(?))
	private attach() : void {
		this.hostElement.insertAdjacentElement("afterbegin", this.formElement);
	}

}
