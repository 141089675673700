import {ProjectInput} from "./ProjectInput";
// eslint-disable-next-line @typescript-eslint/no-var-requires
require("./css/app.css").toString();

console.log("test");

const projectInput = new ProjectInput();

// avoid 'unused variable' errors for now.
console.log(projectInput);
