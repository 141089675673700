
// When you put this decorator (@AutobindToThis) above a function, *this* will always refer the the Class instance. 
export function AutobindToThis(
	_target : any, // prototype of instance
	_methodName : string,
	descriptor : PropertyDescriptor,
) : PropertyDescriptor {
	const originalMethod = descriptor.value;
	const adjustedDescriptor : PropertyDescriptor = {
		get() { // will be triggered by the instance it belongs to.
			const boundFunction = originalMethod.bind(this); // this = the instance. Not the event listener.
			return boundFunction;
		}
	};
	return adjustedDescriptor;
}
