export interface Validatable {
	value : string | number;
	required ?: boolean;			// "?" means optional
	stringLengthMin ?: number;
	stringLengthMax ?: number;
	numberValueMin ?: number;
	numberValueMax ?: number;
}

export function validate (validatableInput : Validatable) : boolean {
	let isValid = true;
	// Validate string given (not empty)
	if (validatableInput.required) {
		isValid &&= validatableInput.value.toString().trim().length !== 0;
	}

	// Validate minimum string length
	if (validatableInput.stringLengthMin != null) {	// "!= null" means "not null or undefined". It is checked this way, because 0 is 'falsy'.
		if (typeof validatableInput.value !== "string")
			throw `'stringLengthMin' can only be used to validate strings. A ${typeof validatableInput.value} was given.`;
			
		isValid &&= validatableInput.value.trim().length >= validatableInput.stringLengthMin;
	}
		
	// Validate maximum string length
	if (validatableInput.stringLengthMax != null) {
		if (typeof validatableInput.value !== "string")
			throw `'stringLengthMax' can only be used to validate strings. A ${typeof validatableInput.value} was given.`;
		
		isValid &&= validatableInput.value.trim().length <= validatableInput.stringLengthMax;
	}
		
	// Validate minimum number
	if (validatableInput.numberValueMin) {
		if (typeof validatableInput.value !== "number")
			throw `'numberValueMin' can only be used to validate numbers. A ${typeof validatableInput.value} was given.`;
		
		isValid &&= validatableInput.value >= validatableInput.numberValueMin;
	}
		
	// Validate maximum number
	if (validatableInput.numberValueMax) {
		if (typeof validatableInput.value !== "number")
			throw `'numberValueMax' can only be used to validate numbers. A ${typeof validatableInput.value} was given.`;
		
		isValid &&= validatableInput.value <= validatableInput.numberValueMax;
	}

	return isValid;
}
